import { createAction, props } from "@ngrx/store";
import { Languages } from "src/app/enums";
import {
  AdminControllerGetEngagementFlowsWithProgramEngagementFlowRequestParams,
  CouponControllerGetCouponByIdRequestParams,
  CouponDto,
  EngagementFlowWithProgramEngagementFlowDto,
  Feature,
  Program,
  ProgramControllerGetProgramIntegrationsRequestParams,
  ProgramIntegration,
} from "src/app/services/api";

export const changeLanguage = createAction("@app/change-language", props<{ language: Languages }>());
export const changeLanguageSuccess = createAction("@app/change-language-success", props<{ language: Languages }>());

export const getProgram = createAction("@app/get-program", props<{ programId?: string; slug?: string }>());
export const getProgramSuccess = createAction("@app/get-program-success", props<{ program: Program }>());
export const getProgramFailure = createAction("@app/get-program-failure", props<{ error: string }>());

export const getProgramIntegrations = createAction(
  "@app/get-program-integrations",
  props<ProgramControllerGetProgramIntegrationsRequestParams>(),
);
export const getProgramIntegrationsSuccess = createAction(
  "@app/get-program-integrations-success",
  props<{ programIntegrations: ProgramIntegration[] }>(),
);
export const getProgramIntegrationsFailure = createAction("@app/get-program-integrations-failure");

export const getFeatures = createAction("@app/get-features");
export const getFeaturesSuccess = createAction("@app/get-features-success", props<{ features: Feature[] }>());
export const getFeaturesFailure = createAction("@app/get-features-failure");

export const setSessionId = createAction("@app/set-session-id", props<{ sessionId: string }>());

export const getCoupon = createAction("@app/get-coupon", props<CouponControllerGetCouponByIdRequestParams>());
export const getCouponSuccess = createAction("@app/get-coupon-success", props<{ coupon: CouponDto }>());
export const getCouponFailure = createAction("@app/get-coupon-failure", props<{ error: string }>());

export const getEngagementFlowsWithProgramEngagementFlow = createAction(
  "@app/get-engagement-flows-with-program-engagement-flow",
  props<
  AdminControllerGetEngagementFlowsWithProgramEngagementFlowRequestParams & {
    successCallback: (engagementFlows: EngagementFlowWithProgramEngagementFlowDto[]) => void;
    errorCallback: (error: string) => void;
  }
  >(),
);
export const getEngagementFlowsWithProgramEngagementFlowSuccess = createAction(
  "@app/get-engagement-flows-with-program-engagement-flow-success",
  props<{
    engagementFlows: EngagementFlowWithProgramEngagementFlowDto[];
    successCallback: (engagementFlows: EngagementFlowWithProgramEngagementFlowDto[]) => void;
  }>(),
);
export const getEngagementFlowsWithProgramEngagementFlowFailure = createAction(
  "@app/get-engagement-flows-with-program-engagement-flow-failure",
  props<{
    error: string;
    errorCallback: (error: string) => void;
  }>(),
);

export const resetError = createAction("@app/reset-error");
