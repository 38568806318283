import { createReducer, on } from "@ngrx/store";
import { Rewards, UserState, initialState } from "./state";
import * as UserActions from "./actions";
import { jwtDecode } from "jwt-decode";

export const userReducer = createReducer(
  initialState,

  on(UserActions.setAccessToken, (state, { accessToken }): UserState => {
    let userId: string;

    try {
      const payload = jwtDecode(accessToken);
      userId = payload["id"];
    } catch (error) {}

    return { ...state, accessToken, userId };
  }),

  on(UserActions.logout, () => ({ ...initialState })),

  on(UserActions.resetError, (state): UserState => ({ ...state, error: "" })),

  on(
    UserActions.register,
    (state): UserState => ({
      ...state,
      error: "",
      loading: true,
    }),
  ),

  on(
    UserActions.downloadPass,
    UserActions.checkCustomerExists,
    UserActions.sendPassToCustomer,
    UserActions.validateCustomer,
    UserActions.getApplePass,
    UserActions.getGooglePass,
    UserActions.getCustomerData,
    UserActions.getCustomerRewards,
    UserActions.activateCustomerReward,
    UserActions.deactivateCustomerReward,
    UserActions.sendValidationEmail,
    UserActions.updateCustomerPreferences,
    UserActions.storeCustomerClickAndRedirect,
    UserActions.claimCustomerReward,
    UserActions.openShopLink,
    (state): UserState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    UserActions.checkCustomerExistsFailure,
    UserActions.checkCustomerExistsSuccess,
    UserActions.sendPassToCustomerSuccess,
    UserActions.validateCustomerFailure,
    UserActions.validateCustomerSuccess,
    UserActions.registerFailure,
    UserActions.registerSuccess,
    UserActions.getApplePassFailure,
    UserActions.getApplePassSuccess,
    UserActions.getGooglePassFailure,
    UserActions.getCustomerDataFailure,
    UserActions.getCustomerRewardsFailure,
    UserActions.activateCustomerRewardFailure,
    UserActions.deactivateCustomerRewardFailure,
    UserActions.sendValidationEmailSuccess,
    UserActions.sendValidationEmailFailure,
    UserActions.updateCustomerPreferencesFailure,
    UserActions.getGooglePassSuccess,
    UserActions.storeCustomerClickAndRedirectSuccess,
    UserActions.storeCustomerClickAndRedirectFailure,
    UserActions.openShopLinkSuccess,
    UserActions.openShopLinkFailure,
    (state): UserState => ({
      ...state,
      loading: false,
    }),
  ),

  on(
    UserActions.claimCustomerRewardFailure,
    (state, { error }): UserState => ({
      ...state,
      error: error ? error.error?.response?.errorType || error.error?.error?.message || error.error?.message || "GENERAL_ERROR" : error,
      loading: false,
    }),
  ),

  on(
    UserActions.sendPassToCustomerFailure,
    (state, { error }): UserState => ({
      ...state,
      error: error?.error?.response?.errorType || error?.error?.error?.message || error?.error?.message || "GENERAL_ERROR",
      loading: false,
    }),
  ),

  on(
    UserActions.setError,
    (state, { error }): UserState => ({
      ...state,
      error: error?.error?.response?.errorType || error?.error?.error?.message || error?.error?.message || "GENERAL_ERROR",
    }),
  ),

  on(
    UserActions.getCustomerDataSuccess,
    (state, { customerData }): UserState => ({
      ...state,
      loading: false,
      customerData,
    }),
  ),

  on(UserActions.getCustomerRewardsSuccess, (state, { rewards }): UserState => {
    const entities: Rewards = {};
    rewards.forEach(reward => (entities[reward.id] = reward));

    return {
      ...state,
      loading: false,
      rewards: entities,
    };
  }),

  on(
    UserActions.activateCustomerRewardSuccess,
    (state, { rewardId }): UserState => ({
      ...state,
      loading: false,
      rewards: {
        ...state.rewards,
        [rewardId]: {
          ...state.rewards[rewardId],
          active: true,
        },
      },
    }),
  ),

  on(
    UserActions.deactivateCustomerRewardSuccess,
    (state, { rewardId }): UserState => ({
      ...state,
      loading: false,
      rewards: {
        ...state.rewards,
        [rewardId]: {
          ...state.rewards[rewardId],
          active: false,
        },
      },
    }),
  ),

  on(UserActions.updateCustomerPreferencesSuccess, (state, { customer }) => ({
    ...state,
    customerData: {
      ...state.customerData,
      customer,
    },
    loading: false,
  })),

  on(
    UserActions.claimCustomerRewardSuccess,
    (state, { claimedReward }): UserState => ({
      ...state,
      loading: false,
      claimedRewards: {
        ...state.claimedRewards,
        [claimedReward?.externalRewardId]: claimedReward,
      },
    }),
  ),

  on(
    UserActions.emptyClaimedRewards,
    (state): UserState => ({
      ...state,
      claimedRewards: {},
    }),
  ),

  on(
    UserActions.setReferral,
    (state, { referral }): UserState => ({
      ...state,
      referral,
    }),
  ),

  on(UserActions.setUTMValues, (state, { utm }) => ({ ...state, utm })),
);
