import { Environment } from "src/app/interfaces";

export const environment: Environment = {
  production: true,
  name: "staging",
  release: "0.0.27#310",
  dist: "310",
  apiUrl: "https://api-staging.jericommerce.com",
  appUrl: "https://staging.jeri.link",
  firebaseConfig: {
    apiKey: "AIzaSyBdeGI6xU04PMMTUX9MvttuOPGtrHqAEE0",
    authDomain: "jeripass-customer-app.firebaseapp.com",
    projectId: "jeripass-customer-app",
    storageBucket: "jeripass-customer-app.appspot.com",
    messagingSenderId: "1086128644346",
    appId: "1:1086128644346:web:b18d543e043357b8ab2943",
  },
};
