import * as UserActions from "src/app/store/user/actions";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of, catchError, mergeMap, map, tap, withLatestFrom, first, filter, forkJoin, from } from "rxjs";
import {
  MinimalPassDto,
  ProgramControllerGetAnonymousCouponApplePassRequestParams,
  ProgramControllerGetApplePassRequestParams,
  ProgramControllerGetCouponUniqueCodeApplePassRequestParams,
  ProgramService,
  AdminService,
} from "src/app/services/api";
import { HttpClient, HttpErrorResponse, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { Action, Store } from "@ngrx/store";
import { RootState } from "..";
import { AppRoutes, Platform } from "src/app/enums";
import * as FromRouter from "src/app/store/router/selectors";
import { InfoComponent, InfoComponentParams } from "src/app/modals/info/info.component";
import { InfoPageParams } from "src/app/pages/info/info.page";
import { MULTI_PASS } from "src/app/constants";
import { UtilsService } from "src/app/services/utils/utils.service";
import { DownloadedPageParams } from "src/app/pages/downloaded/downloaded.page";
import { TranslateService } from "@ngx-translate/core";
import * as FromApp from "src/app/store/app/selectors";
import * as FromUser from "src/app/store/user/selectors";

@Injectable()
export class UserEffects {
  public checkCustomerExists$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.checkCustomerExists),
      mergeMap(params =>
        this.programService.programControllerCheckCustomerExists(params).pipe(
          mergeMap(() => of(UserActions.checkCustomerExistsSuccess())),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.checkCustomerExistsFailure({ error, email: params.checkCustomer.email })),
          ),
        ),
      ),
    ),
  );

  public checkCustomerExistsFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.checkCustomerExistsFailure),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(([{ error, email }, slug]) => {
          switch (error.status) {
            case HttpStatusCode.NotFound:
              this.navController.navigateForward([slug, AppRoutes.register], {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
            case HttpStatusCode.Found:
              this.navController.navigateForward([slug, AppRoutes.sendPass], {
                queryParams: { email: encodeURIComponent(email) },
              });
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  public sendPassToCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendPassToCustomer),
      mergeMap(params =>
        this.programService.programControllerSendPassToCustomer(params).pipe(
          mergeMap(() => of(UserActions.sendPassToCustomerSuccess({ email: params.checkCustomer.email }))),
          catchError((error: HttpErrorResponse) => of(UserActions.sendPassToCustomerFailure({ error }))),
        ),
      ),
    ),
  );

  public sendPassToCustomerSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.sendPassToCustomerSuccess),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(async ([{ email }, programSlug]) => {
          const data: InfoPageParams = {
            text: "SEND_PASS_SUCCESS",
            mailToOpen: email,
            lottie: {
              name: "assets/lotties/pass-in-email.lottie.json",
              loop: false,
              height: "120px",
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.info], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public validateCustomer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.validateCustomer),
      mergeMap(params =>
        this.programService.programControllerValidateCustomer(params).pipe(
          mergeMap(() => of(UserActions.validateCustomerSuccess())),
          catchError(error => of(UserActions.validateCustomerFailure({ error }))),
        ),
      ),
    ),
  );

  public validateCustomerSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.validateCustomerSuccess),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(async ([_, programSlug]) => {
          const data: InfoPageParams = {
            text: "CUSTOMER_VALIDATION_SUCCESS",
            lottie: {
              name: "assets/lotties/success.lottie.json",
              loop: false,
              height: "120px",
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.info], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public validateCustomerFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.validateCustomerFailure),
        filter(({ error }) => error?.status !== HttpStatusCode.Unauthorized),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        first(([_, programSlug]) => !!programSlug),
        tap(async ([{ error }, programSlug]) => {
          const data: InfoPageParams = {
            text: error.error.response?.errorType ? error.error.response?.errorType : "GENERAL_ERROR",
            lottie: {
              name: "assets/lotties/error.lottie.json",
              loop: false,
              height: "120px",
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.info], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public downloadPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.downloadPass),
      map(params => {
        if (params.platform) {
          return params.platform === Platform.ios ? UserActions.getApplePass(params.params) : UserActions.getGooglePass(params.params);
        } else {
          return this.utilsService.isIOS() ? UserActions.getApplePass(params.params) : UserActions.getGooglePass(params.params);
        }
      }),
    ),
  );

  public downloadCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.downloadCouponPass),
      map(params => {
        if (params.platform) {
          return params.platform === Platform.ios
            ? UserActions.getAppleCouponPass(params.params)
            : UserActions.getGoogleCouponPass(params.params);
        } else {
          return this.utilsService.isIOS() ? UserActions.getAppleCouponPass(params.params) : UserActions.getGoogleCouponPass(params.params);
        }
      }),
    ),
  );

  public downloadAnonymousCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.downloadAnonymousCouponPass),
      map(params => {
        if (params.platform) {
          return params.platform === Platform.ios
            ? UserActions.getAppleAnonymousCouponPass(params.params)
            : UserActions.getGoogleAnonymousCouponPass(params.params);
        } else {
          return this.utilsService.isIOS()
            ? UserActions.getAppleAnonymousCouponPass(params.params)
            : UserActions.getGoogleAnonymousCouponPass(params.params);
        }
      }),
    ),
  );

  public register$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.register),
      mergeMap(({ programId, registerCustomer, referral, utmCampaign, utmMedium, utmSource, referralLink }) =>
        this.programService
          .programControllerRegisterCustomer({ programId, registerCustomer, referral, utmCampaign, utmMedium, utmSource })
          .pipe(
            mergeMap(({ accessToken }) => {
              this.store.dispatch(UserActions.setAccessToken({ accessToken }));

              if (referralLink) {
                return this.adminService.adminControllerGetEngagementFlowsWithProgramEngagementFlow({ programId }).pipe(
                  mergeMap(engagementFlows => {
                    const memberGetMember = engagementFlows.find(
                      engagementFlow => engagementFlow.name === "MEMBER_GET_MEMBER" && engagementFlow.active,
                    );

                    if (memberGetMember?.programEngagementFlows && memberGetMember?.programEngagementFlows[0]?.active) {
                      const programEngagementFlow = memberGetMember.programEngagementFlows[0];

                      if (programEngagementFlow.properties.sendDownloadEmail) {
                        return this.programService
                          .programControllerSendPassToCustomer({
                            programId,
                            checkCustomer: { email: registerCustomer.email },
                          })
                          .pipe(
                            mergeMap(() =>
                              of(
                                UserActions.openShopLink({
                                  programId,
                                  redirectUrl: referralLink,
                                }),
                              ),
                            ),
                            catchError((error: HttpErrorResponse) =>
                              of(
                                UserActions.registerFailure({
                                  error,
                                  params: { programId, registerCustomer, referral, utmCampaign, utmMedium, utmSource },
                                }),
                              ),
                            ),
                          );
                      } else {
                        return of(
                          UserActions.openShopLink({
                            programId,
                            redirectUrl: referralLink,
                          }),
                        );
                      }
                    } else {
                      return of(
                        this.utilsService.isIOS() ? UserActions.getApplePass({ programId }) : UserActions.getGooglePass({ programId }),
                      );
                    }
                  }),
                  catchError((error: HttpErrorResponse) =>
                    of(
                      UserActions.registerFailure({
                        error,
                        params: { programId, registerCustomer, referral, utmCampaign, utmMedium, utmSource },
                      }),
                    ),
                  ),
                );
              } else {
                return of(this.utilsService.isIOS() ? UserActions.getApplePass({ programId }) : UserActions.getGooglePass({ programId }));
              }
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                UserActions.registerFailure({
                  error,
                  params: { programId, registerCustomer, referral, utmCampaign, utmMedium, utmSource },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  public registerFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.registerFailure),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(async ([{ error, params }, slug]) => {
          if (error?.error?.statusCode === HttpStatusCode.Conflict) {
            this.navController.navigateForward([slug, AppRoutes.sendPass], {
              queryParams: { email: encodeURIComponent(params.registerCustomer.email) },
            });
          } else {
            this.store.dispatch(UserActions.setError({ error }));
          }
        }),
      ),
    { dispatch: false },
  );

  public getApplePass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getApplePass),
      mergeMap(params =>
        this.programControllerGetApplePass(params).pipe(
          mergeMap((response: Blob) => of(UserActions.getApplePassSuccess({ response, params, kind: MinimalPassDto.KindEnum.StoreCard }))),
          catchError((error: HttpErrorResponse) => of(UserActions.getApplePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getAppleCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getAppleCouponPass),
      mergeMap(params =>
        this.programControllerGetCouponUniqueCodeApplePass(params).pipe(
          map((response: Blob) =>
            UserActions.getApplePassSuccess({
              response,
              params,
              kind: MinimalPassDto.KindEnum.Coupon,
              couponCode: params.couponCodeValue,
              couponSlug: params.couponId,
            }),
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.getApplePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getAppleAnonymousCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getAppleAnonymousCouponPass),
      mergeMap(params =>
        this.programControllerGetAnonymousCouponApplePass(params).pipe(
          filter(response => response instanceof HttpResponse),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((response: HttpResponse<any>) =>
            UserActions.getApplePassSuccess({
              response: response.body,
              params,
              kind: MinimalPassDto.KindEnum.Coupon,
              couponCode: params.couponCode,
              couponSlug: params.couponId,
              campaignSlug: params.campaignSlug,
            }),
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.getApplePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getApplePassSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getApplePassSuccess),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(([{ response, kind, couponCode, couponSlug, campaignSlug }, programSlug]) => {
          const blobPkpass = new Blob([response], { type: "application/vnd.apple.pkpass" });
          const url = window.URL.createObjectURL(blobPkpass);

          const data: DownloadedPageParams = {
            text: "PASS_DOWNLOAD_SUCCESS",
            lottie: {
              name: "assets/lotties/pass-downloaded.lottie.json",
              loop: true,
              height: "350px",
            },
            walletPass: {
              url,
              platform: Platform.ios,
              kind,
              couponCode,
              couponSlug,
              campaignSlug,
              name: `${programSlug}-loyalty-wallet-pass-${Date.now()}.pkpass`,
            },
          };

          this.navController.navigateForward([programSlug, AppRoutes.downloaded], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public getApplePassFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getApplePassFailure),
        filter(({ error }) => error?.status !== HttpStatusCode.Unauthorized),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(([error, programSlug]) => {
          const data: DownloadedPageParams = {
            text: error?.error?.message ?? "PASS_DOWNLOAD_ERROR",
            lottie: {
              name: "assets/lotties/error.lottie.json",
              loop: false,
              height: "120px",
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.downloaded], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public getGooglePass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getGooglePass),
      mergeMap(params =>
        this.programService.programControllerGetGooglePass(params).pipe(
          map(response => UserActions.getGooglePassSuccess({ response: { ...response, kind: MinimalPassDto.KindEnum.StoreCard } })),
          catchError((error: HttpErrorResponse) => of(UserActions.getGooglePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getGoogleCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getGoogleCouponPass),
      mergeMap(params =>
        this.programService.programControllerGetCouponUniqueCodeGooglePass(params).pipe(
          map(response =>
            UserActions.getGooglePassSuccess({
              response: {
                ...response,
                kind: MinimalPassDto.KindEnum.Coupon,
                couponSlug: params.couponId,
                couponCode: params.couponCodeValue,
              },
            }),
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.getGooglePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getGoogleAnonymousCouponPass$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getGoogleAnonymousCouponPass),
      mergeMap(params =>
        this.programService.programControllerGetAnonymousCouponGooglePass(params).pipe(
          map(response =>
            UserActions.getGooglePassSuccess({
              response: {
                ...response,
                kind: MinimalPassDto.KindEnum.Coupon,
                couponSlug: params.couponId,
                couponCode: params.couponCode,
                campaignSlug: params.campaignSlug,
              },
            }),
          ),
          catchError((error: HttpErrorResponse) => of(UserActions.getGooglePassFailure({ error }))),
        ),
      ),
    ),
  );

  public getGooglePassSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getGooglePassSuccess),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(async ([{ response }, programSlug]) => {
          const data: DownloadedPageParams = {
            text: "PASS_DOWNLOAD_SUCCESS",
            lottie: {
              name: "assets/lotties/pass-downloaded.lottie.json",
              loop: true,
              height: "350px",
            },
            walletPass: {
              platform: Platform.android,
              kind: response.kind,
              couponCode: response.couponCode,
              couponSlug: response.couponSlug,
              url: response.googlePayURL,
              campaignSlug: response.campaignSlug,
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.downloaded], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public getGooglePassFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getGooglePassFailure),
        filter(({ error }) => error?.status !== HttpStatusCode.Unauthorized),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(async ([error, programSlug]) => {
          const data: DownloadedPageParams = {
            text: error?.error?.error?.message ?? "PASS_DOWNLOAD_ERROR",
            lottie: {
              name: "assets/lotties/error.lottie.json",
              loop: false,
              height: "120px",
            },
          };
          this.navController.navigateForward([programSlug, AppRoutes.downloaded], { state: { data } });
        }),
      ),
    { dispatch: false },
  );

  public getCustomerData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerData),
      mergeMap(params =>
        this.programService.programControllerGetCustomerData(params).pipe(
          mergeMap(customerData => of(UserActions.getCustomerDataSuccess({ customerData }))),
          catchError(() => of(UserActions.getCustomerDataFailure())),
        ),
      ),
    ),
  );

  public getCustomerRewards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getCustomerRewards),
      mergeMap(params =>
        this.programService.programControllerGetCustomerCoupons(params).pipe(
          mergeMap(rewards => of(UserActions.getCustomerRewardsSuccess({ rewards }))),
          catchError(() => of(UserActions.getCustomerRewardsFailure())),
        ),
      ),
    ),
  );

  public activateCustomerReward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.activateCustomerReward),
      mergeMap(params =>
        this.programService.programControllerActivateCustomerReward(params).pipe(
          mergeMap(() => of(UserActions.activateCustomerRewardSuccess({ rewardId: params.rewardId }))),
          catchError(() => of(UserActions.activateCustomerRewardFailure())),
        ),
      ),
    ),
  );

  public deactivateCustomerReward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deactivateCustomerReward),
      mergeMap(params =>
        this.programService.programControllerDeactivateCustomerReward(params).pipe(
          mergeMap(() => of(UserActions.deactivateCustomerRewardSuccess({ rewardId: params.rewardId }))),
          catchError(() => of(UserActions.deactivateCustomerRewardFailure())),
        ),
      ),
    ),
  );

  public sendValidationEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendValidationEmail),
      mergeMap(params =>
        this.programService.programControllerSendValidationEmail(params).pipe(
          mergeMap(() => of(UserActions.sendValidationEmailSuccess({ email: params.checkCustomer.email }))),
          catchError(() => of(UserActions.sendValidationEmailFailure())),
        ),
      ),
    ),
  );

  public sendValidationEmailSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.sendValidationEmailSuccess),
        tap(async ({ email }) => {
          const componentProps: InfoComponentParams = {
            lottie: "assets/lotties/success.lottie.json",
            lottieHeight: "120px",
            text: "SEND_VALIDATION_EMAIL_SUCCESS_TEXT",
            mailToOpen: email,
          };
          const modal = await this.modalController.create({
            component: InfoComponent,
            componentProps,
            canDismiss: true,
            backdropDismiss: true,
            showBackdrop: true,
          });
          await modal.present();
        }),
      ),
    { dispatch: false },
  );

  public updateCustomerPreferences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateCustomerPreferences),
      mergeMap(({ programId, customerId, updateCustomerPreferences }) =>
        this.programService.programControllerUpdateCustomerPreferences({ programId, customerId, updateCustomerPreferences }).pipe(
          map(customer => UserActions.updateCustomerPreferencesSuccess({ customer })),
          catchError(() => of(UserActions.updateCustomerPreferencesFailure())),
        ),
      ),
    ),
  );

  public logout$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(({ callback }) => callback()),
      ),
    { dispatch: false },
  );

  public openShopLink$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.openShopLink),
      withLatestFrom(this.store.select(FromApp.selectIsFeatureEnabledByName("multi-pass"))),
      mergeMap(([params, isMultiPassActive]) =>
        isMultiPassActive
          ? this.programService.programControllerGetCustomerMultiPassLink(params).pipe(
            map(links => UserActions.openShopLinkSuccess({ links })),
            catchError(() => of(UserActions.openShopLinkFailure())),
          )
          : of(UserActions.openShopLinkSuccess({ links: [{ link: params.redirectUrl, integration: null }] })),
      ),
    ),
  );

  public openShopLinkSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.openShopLinkSuccess),
        filter(({ links }) => !!links?.length),
        tap(({ links }) => {
          const redirectTo = links[0].link;

          if (redirectTo?.includes("https") || redirectTo?.includes("http")) {
            const newPage = window.open(redirectTo, "_blank");
            if (!newPage || newPage.closed || typeof newPage.closed === "undefined") window.open(redirectTo, "_self");
          }
        }),
      ),
    { dispatch: false },
  );

  public storeCustomerClickAndRedirect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.storeCustomerClickAndRedirect),
      mergeMap(params => {
        let redirectTo = undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sources: Observable<any>[] = [this.programService.programControllerStoreCustomerClick(params)];

        if (params.createClick.url.toLowerCase().includes(`%%${MULTI_PASS.toLowerCase()}`)) {
          const regex = /\|(.*?)%%/;
          const result = regex.exec(params.createClick.url);
          if (result) redirectTo = result[1];

          sources.push(
            this.programService.programControllerGetCustomerMultiPassLink({ programId: params.programId, redirectUrl: redirectTo }),
          );
        } else if (params.createClick.url.startsWith(AppRoutes.profile)) {
          sources.push(this.programService.programControllerGetCustomerData({ programId: params.programId }));
          redirectTo = params.createClick.url;
        } else {
          redirectTo = params.createClick.url;
        }

        return forkJoin(sources).pipe(
          mergeMap(([, response]) => {
            if (response.customer) {
              return [
                UserActions.getCustomerDataSuccess({ customerData: response }),
                UserActions.storeCustomerClickAndRedirectSuccess({ redirectTo }),
              ];
            } else if (response) {
              return [UserActions.storeCustomerClickAndRedirectSuccess({ redirectTo: response[0].link })];
            } else {
              return [UserActions.storeCustomerClickAndRedirectSuccess({ redirectTo })];
            }
          }),
          catchError(error => of(UserActions.storeCustomerClickAndRedirectFailure({ redirectTo, error }))),
        );
      }),
    ),
  );

  public storeCustomerClickAndRedirectSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.storeCustomerClickAndRedirectSuccess),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(([{ redirectTo }, programSlug]) => {
          this.storeCustomerClickAndRedirect(redirectTo, programSlug);
        }),
      ),
    { dispatch: false },
  );

  public storeCustomerClickAndRedirectFailure$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.storeCustomerClickAndRedirectFailure),
        filter(({ error }) => error?.status !== HttpStatusCode.Unauthorized),
        withLatestFrom(this.store.select(FromRouter.selectProgramSlug)),
        tap(([{ redirectTo }, programSlug]) => {
          this.storeCustomerClickAndRedirect(redirectTo, programSlug);
        }),
      ),
    { dispatch: false },
  );

  public claimCustomerReward$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.claimCustomerReward),
      mergeMap(({ params, balance, balanceName }) =>
        from(this.showClaimAlert(balance, balanceName)).pipe(
          mergeMap(role =>
            role === "cancel"
              ? of(UserActions.claimCustomerRewardFailure({ error: null }))
              : this.programService.programControllerClaimCustomerReward(params).pipe(
                map(claimedReward => UserActions.claimCustomerRewardSuccess({ claimedReward })),
                catchError((error: HttpErrorResponse) => of(UserActions.claimCustomerRewardFailure({ error }))),
              ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly httpClient: HttpClient,
    private readonly navController: NavController,
    private readonly programService: ProgramService,
    private readonly store: Store<RootState>,
    private readonly modalController: ModalController,
    private readonly utilsService: UtilsService,
    private readonly alertController: AlertController,
    private readonly translateService: TranslateService,
    private readonly adminService: AdminService,
  ) {}

  private async showClaimAlert(balance: number, balanceName: string): Promise<string> {
    const alert = await this.alertController.create({
      header: this.translateService.instant("CLAIM_HEADER", { balance, balanceName }),
      message: this.translateService.instant("CLAIM_MESSAGE"),
      buttons: [
        {
          role: "cancel",
          text: this.translateService.instant("CLAIM_CANCEL"),
        },
        {
          text: this.translateService.instant("CLAIM_CONFIRM"),
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  private storeCustomerClickAndRedirect(redirectTo: string, programSlug: string): void {
    if (redirectTo) {
      if (redirectTo.includes("https") || redirectTo.includes("http")) {
        window.location.href = redirectTo;
      } else {
        this.navController.navigateForward([programSlug, ...decodeURIComponent(redirectTo).split("/").filter(Boolean)], {
          replaceUrl: true,
        });
      }
    } else {
      this.navController.navigateForward([programSlug, AppRoutes.profile]);
    }
  }

  private programControllerGetApplePass(
    requestParameters: ProgramControllerGetApplePassRequestParams,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<unknown> {
    const programId = requestParameters.programId;

    if (programId === null || programId === undefined) {
      throw new Error("Required parameter programId was null or undefined when calling programControllerGetApplePass.");
    }

    let headers = this.programService.defaultHeaders;

    let credential: string | undefined;
    // authentication (bearer) required
    credential = this.programService.configuration.lookupCredential("bearer");

    if (credential) {
      headers = headers.set("Authorization", "Bearer " + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;

    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected = this.programService.configuration.selectHeaderAccept(httpHeaderAccepts);
    }

    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];

    const httpContentTypeSelected: string | undefined = this.programService.configuration.selectHeaderContentType(consumes);

    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.get(
      `${this.programService.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/apple/pass`,
      {
        responseType: "blob",
        withCredentials: this.programService.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  private programControllerGetCouponUniqueCodeApplePass(
    requestParameters: ProgramControllerGetCouponUniqueCodeApplePassRequestParams,
    observe?: "body",
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined },
  ): Observable<unknown> {
    const programId = requestParameters.programId;
    if (programId === null || programId === undefined) {
      throw new Error("Required parameter programId was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.");
    }
    const couponId = requestParameters.couponId;
    if (couponId === null || couponId === undefined) {
      throw new Error("Required parameter couponId was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.");
    }
    const couponCodeValue = requestParameters.couponCodeValue;
    if (couponCodeValue === null || couponCodeValue === undefined) {
      throw new Error(
        "Required parameter couponCodeValue was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.",
      ); // eslint-disable-line max-len
    }

    let headers = this.programService.defaultHeaders;

    let credential: string | undefined;
    // authentication (bearer) required
    credential = this.programService.configuration.lookupCredential("bearer");
    if (credential) {
      headers = headers.set("Authorization", "Bearer " + credential);
    }

    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected = this.programService.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get(
      `${this.programService.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/coupons/${encodeURIComponent(String(couponId))}/codes/${encodeURIComponent(String(couponCodeValue))}/apple/pass`,
      {
        responseType: "blob",
        withCredentials: this.programService.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  private programControllerGetAnonymousCouponApplePass(
    requestParameters: ProgramControllerGetAnonymousCouponApplePassRequestParams,
    reportProgress: boolean = false,
  ): Observable<unknown> {
    const programId = requestParameters.programId;
    if (programId === null || programId === undefined) {
      throw new Error("Required parameter programId was null or undefined when calling programControllerGetAnonymousCouponApplePass.");
    }
    const couponId = requestParameters.couponId;
    if (couponId === null || couponId === undefined) {
      throw new Error("Required parameter couponId was null or undefined when calling programControllerGetAnonymousCouponApplePass.");
    }
    const campaignSlug = requestParameters.campaignSlug;
    if (campaignSlug === null || campaignSlug === undefined) {
      throw new Error("Required parameter campaignSlug was null or undefined when calling programControllerGetAnonymousCouponApplePass.");
    }

    let headers = this.programService.defaultHeaders;

    return this.httpClient
      .get(
        `${this.programService.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/anonymous-coupons/${encodeURIComponent(String(couponId))}/apple/pass`,
        {
          params: {
            "campaign-slug": requestParameters.campaignSlug,
            "coupon-code": requestParameters.couponCode,
          },
          responseType: "blob",
          withCredentials: this.programService.configuration.withCredentials,
          headers: headers,
          observe: "events",
          reportProgress: reportProgress,
        },
      )
      .pipe(catchError(this.parseErrorBlob));
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private parseErrorBlob(error: HttpErrorResponse): any {
    const reader: FileReader = new FileReader();
    const observable = new Observable((observer: any) => {
      reader.onloadend = (e): void => {
        observer.error(JSON.parse(reader.result as any));
        observer.complete();
      };
    });
    reader.readAsText(error.error);
    return observable;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
}
