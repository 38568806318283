import { createReducer, on } from "@ngrx/store";
import { AppState, initialState } from "./state";
import * as AppActions from "./actions";

export const appReducer = createReducer(
  initialState,

  on(
    AppActions.getProgram,
    AppActions.getFeatures,
    AppActions.getProgramIntegrations,
    AppActions.getCoupon,
    AppActions.getEngagementFlowsWithProgramEngagementFlow,
    (state): AppState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    AppActions.getProgramFailure,
    AppActions.getFeaturesFailure,
    AppActions.getProgramIntegrationsFailure,
    AppActions.getCouponFailure,
    AppActions.getEngagementFlowsWithProgramEngagementFlowSuccess,
    AppActions.getEngagementFlowsWithProgramEngagementFlowFailure,
    (state): AppState => ({
      ...state,
      loading: false,
    }),
  ),

  on(
    AppActions.getProgramSuccess,
    (state, { program }): AppState => ({
      ...state,
      loading: false,
      program,
    }),
  ),

  on(
    AppActions.getProgramIntegrationsSuccess,
    (state, { programIntegrations }): AppState => ({
      ...state,
      loading: false,
      programIntegrations,
    }),
  ),

  on(
    AppActions.getFeaturesSuccess,
    (state, { features }): AppState => ({
      ...state,
      loading: false,
      features,
    }),
  ),

  on(AppActions.getCouponSuccess, (state, { coupon }) => ({
    ...state,
    coupon,
    loading: false,
  })),

  on(AppActions.setSessionId, (state, { sessionId }) => ({ ...state, session: { id: sessionId, dateTime: new Date() } })),

  on(AppActions.changeLanguageSuccess, (state, { language }): AppState => ({ ...state, language })),
);
